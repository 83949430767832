import React, { useState, useEffect } from 'react'
import { Container, Col, Row, Button } from 'reactstrap'
import axios from 'axios'

import './index.scss'

import Layout from '../../components/layout'
import SEO from '../../components/seo'
import ProfileLogo from '../../components/ProfileLogo/profileLogo'
import CareCard from '../../components/CareCard/careCard'

const ProfilePage = () => {
	const [profile, setProfileData] = useState({})
	const [cardData, setCardData] = useState({})
	const [userId, setUserId] = useState(0)
	const [letter, setLetter] = useState('')

	useEffect(() => {
		async function getProfileData() {
			try {
				const userId = window.localStorage.getItem('userId')

				if (userId) {
					const response = await axios.get(`${process.env.DATABASE_ROOT_URL}${process.env.DATABASE_STAGE}/users/id/${userId}`)
					const cardResponse = await axios.get(`${process.env.DATABASE_ROOT_URL}${process.env.DATABASE_STAGE}/cpcs/user/${userId}`)

					setUserId(userId)
					setCardData(cardResponse.data.cpcs)
					setProfileData(response.data.user)
					setLetter(response.data.user.name.charAt(0))
				}
			} catch (error) {
				console.error('Error getting card data: ', error)
			}
		}
		getProfileData()
	}, [])

	return (
		<Layout>
			<SEO title='Profile' />
			{userId ? (
				<Container>
					<Row className='px-3'>
						<Col lg={1}></Col>
						<Col lg={3} className=''>
							<ProfileLogo size={'large'} letter={letter} />
						</Col>
						<Col lg={7}>
							<Row className='profile-name pt-1'>
								{profile.name}{' '}
								<span className='profile-count pl-2'>
									{cardData.length} {cardData.length === 1 ? 'Carepostcard' : 'Carepostcards'}
								</span>
							</Row>
							<Row className='profile-tag pb-3'>{profile.mentionHandle}</Row>
							<Row className='profile-bio py-1'>
								Small acts of kindness when multiplied by millions will transform the world.
								{/* 								
								Here is a link to my portfolio to see my
								work
								<a href='http://www.wambi.org' target='_blank' rel='noopener noreferrer'>
									www.wambi.org
								</a> */}
							</Row>
							{/* <Row className='profile-edit'>
								<a href='/'>Edit Bio</a>
							</Row> */}
						</Col>
						<Col lg={1}></Col>
					</Row>
					<Col>
						<Row className='profile-create-row'>
							<Button href='/create' className='profile-create-button'>
								Post
							</Button>
						</Row>
						<Row className='p-3 justify-content-center'>
							{cardData.length ? (
								cardData.map((_, index) => {
									return (
										<Row key={index}>
											<div className='px-2'>
												<CareCard post={_} />
											</div>
										</Row>
									)
								})
							) : (
								<Row className='profile-no-results px-4 pb-3'>No cards found.</Row>
							)}
						</Row>
					</Col>
				</Container>
			) : null}
		</Layout>
	)
}

export default ProfilePage
