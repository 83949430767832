import React, { useState, useEffect } from 'react'
import { Link } from 'gatsby'
import { Card, CardText, CardBody, CardTitle, CardSubtitle, Button, Col, Row, Input } from 'reactstrap'
import { MdLocationOn } from 'react-icons/md'
import { AiFillHeart, AiOutlineHeart } from 'react-icons/ai'
// import moment from 'moment'

import './careCard.scss'

const CareCard = props => {
	const [heart, setHeart] = useState(false)
	const [isLoading, setIsLoading] = useState(false)
	const [editButtons, setEditButtons] = useState(false)
	const [newBody, setNewBody] = useState(props.post.body)

	async function toggleHeart() {
		setHeart(!heart)
	}

	// Gets fuzzy date for each card
	// const timePast = posted => {
	// 	return moment(posted).fromNow()
	// }

	// Formats to body to show styled hashtags
	function formatBody() {
		return {
			__html: props.post.body.replace(
				/(^|\s)(#)([a-z\d-]+)/gi,
				`$1<a href=${`/hashtag/?hashtag=$3`} alt=''><span class='care-card-hashtag'>$2$3</span></a>`
			),
		}
	}

	useEffect(() => {
		if (!props.isUpdating) {
			setIsLoading(false)
		}
	}, [props.isUpdating])

	const handleApprovePost = () => {
		setIsLoading(true)
		props.approvePost(props.post.id)
	}

	const handleHidePost = () => {
		setIsLoading(true)
		props.hidePost(props.post.id)
	}

	const handleFeaturePost = () => {
		setIsLoading(true)
		props.updatePost(props.post.id, { featured: !props.post.featured })
	}

	const handleBodyChange = e => {
		setEditButtons(true)
		setNewBody(e.target.value)
	}

	const handleEditPost = () => {
		props.updatePost(props.post.id, { body: newBody })
		setEditButtons(false)
	}

	const handleEditCancel = () => {
		setNewBody(props.post.body)
		setEditButtons(false)
	}

	const getLocationName = () => {
		if (props.post.tempOrgName) {
			return props.post.tempOrgName
		} else if (props.post.name) {
			return props.post.name
		} else {
			return 'No location attached'
		}
	}

	const getLocationAddress = () => {
		if (props.post.tempCity || props.post.tempState) {
			return `${formatToTitleCase(props.post.tempCity)}, ${props.post.tempState.toUpperCase()}`
		} else if (props.post.city || props.post.state) {
			return `${formatToTitleCase(props.post.city)}, ${props.post.state.toUpperCase()}`
		} else {
			return 'No location attached'
		}
	}

	const formatToTitleCase = str => {
		if (str) {
			str = str.toLowerCase()
			str = str.split(' ')

			for (var i = 0; i < str.length; i++) {
				str[i] = str[i].charAt(0).toUpperCase() + str[i].slice(1)
			}

			return str.join(' ')
		} else {
			return str
		}
	}

	return (
		<div>
			<Card className='care-card m-3'>
				{props.showModerationOptions ? (
					<img src={props.post.photoUrl} alt='Comment image' className='care-card-image' />
				) : (
					<Link to={`/detail/?id=${props.post.id}`} alt=''>
						<img src={props.post.photoUrl} alt='Comment image' className='care-card-image' />
					</Link>
				)}
				{props.post.artistName && (
					<span className='care-card-image-caption'>
						Photo by&nbsp;
						<a
							href={`https://www.unsplash.com/${props.post.artistHandle}?utm_source=carepostcard&utm_medium=referral`}
							target='_blank'
							rel='noopener noreferrer'
						>
							{props.post.artistName}
						</a>
						&nbsp;on&nbsp;
						<a href='https://www.unsplash.com?utm_source=carepostcard&utm_medium=referral' target='_blank' rel='noopener noreferrer'>
							Unsplash
						</a>
					</span>
				)}
				<CardBody className='care-card-body'>
					{props.showModerationOptions ? (
						<>
							<Row className='pb-2'>
								<Col xs={1}>
									<MdLocationOn className='care-card-map-icon ml-1' />
								</Col>
								<Col xs={10} className='text-left ml-2'>
									<CardTitle className='care-card-title'>{getLocationName()}</CardTitle>
									<CardSubtitle className='care-card-subtitle'>{getLocationAddress()}</CardSubtitle>
								</Col>
							</Row>
							<Row className='text-left px-4 pt-3'>
								<CardText className='care-card-recipient'>
									To: {props.post.addressedTo ? props.post.addressedTo : 'Your Amazing Team'}
								</CardText>
							</Row>{' '}
						</>
					) : (
						<Link to={`/detail/?id=${props.post.id}`} alt='' className=''>
							<Row className='pb-2'>
								<Col xs={1}>
									<MdLocationOn className='care-card-map-icon ml-1' />
								</Col>
								<Col xs={10} className='text-left ml-2'>
									<CardTitle className='care-card-title'>{getLocationName()}</CardTitle>
									<CardSubtitle className='care-card-subtitle'>{getLocationAddress()}</CardSubtitle>
								</Col>
							</Row>
							<Row className='text-left px-4 pt-3'>
								<CardText className='care-card-recipient'>
									To: {props.post.addressedTo ? props.post.addressedTo : 'Your Amazing Team'}
								</CardText>
							</Row>
						</Link>
					)}
					{props.showModerationOptions ? (
						<>
							<Row className='text-left px-3 py-2'>
								<Input
									type='textarea'
									name='body'
									id='body'
									className='care-card-textarea'
									value={newBody}
									onChange={e => {
										handleBodyChange(e)
									}}
								/>
							</Row>
							<Row className='justify-content-end px-4 py-2'>
								{editButtons && (
									<>
										<Button size='sm' outline color='primary' onClick={handleEditCancel} disabled={isLoading}>
											Cancel
										</Button>
										<Button size='sm' color='primary' onClick={handleEditPost} disabled={isLoading} className='ml-3'>
											Update
										</Button>
									</>
								)}
							</Row>
						</>
					) : (
						<Link to={`/detail/?id=${props.post.id}`} alt='' className=''>
							<Row className='text-left px-4 py-2'>
								<CardText className='care-card-text' dangerouslySetInnerHTML={formatBody()}></CardText>
							</Row>
						</Link>
					)}
				</CardBody>
				<Row className='care-card-footer'>
					<CardText className='care-card-timestamp'>
						{/* {timePast(props.post.postedOn)}  */}
						&nbsp;
					</CardText>
					<Button hidden onClick={() => toggleHeart()} className='care-card-likes'>
						{heart ? <AiFillHeart className='care-card-heart-filled' /> : <AiOutlineHeart className='care-card-heart-unfilled' />} 103
						likes
					</Button>
				</Row>
			</Card>
			{props.showModerationOptions ? (
				<Row className='justify-content-between px-5 mb-4'>
					<Button size='sm' outline color='primary' onClick={handleApprovePost} disabled={isLoading}>
						Approve
					</Button>{' '}
					<Button type='button' size='sm' outline color='secondary' onClick={handleHidePost} disabled={isLoading}>
						Hide
					</Button>{' '}
					<Button size='sm' outline color='warning' disabled={isLoading} onClick={handleFeaturePost}>
						{props.post.featured ? 'Unfeature' : 'Feature'}
					</Button>
				</Row>
			) : null}
		</div>
	)
}

CareCard.defaultProps = {
	showModerationOptions: false,
	isUpdating: false,
	approvePost: () => {},
	hidePost: () => {},
	updatePost: () => {},
	openModalDialog: () => {},
}

export default CareCard
